<template>
  <section>
    <button
      class="refresh-button btn btn-sm btn-outline-primary"
      @click="handleRefreshButton"
      :disabled="!qsReports.length"
    >
      Refresh Dashboards
      <Tooltip text="Click to refresh dashboards and get the latest updates (loading time: 40 seconds)"></Tooltip>
    </button>
    <div class="category">
      <div class="loading py-5" v-if="loading">
        <Loading />
      </div>
      <div v-else>
        <h3>Select Report</h3>
      </div>
      <div class="category__tile-container">
        <div v-for="report in qsReports" :key="report.name">
          <div class="category__tile" @click="selectedReport(report.dashboardId, report.name)">
            <h4>{{ report.name }}</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { API, Auth } from "aws-amplify";
import Loading from "@/components/common/Loading";
import {
  getQuickSightDashboards,
  getUploadToBbUrlAndAuthKey,
  fetchDashboardEmbedUrl,
  refreshDashboards,
} from "@/graphql/queries";
import { fetchGameFitPermission } from "../../graphql/queries";
import Tooltip from "@/components/common/Tooltip";
import { mapGetters } from "vuex";

export default {
  components: {
    Loading,
    Tooltip,
  },
  data() {
    return {
      loading: true,
      qsReports: [],
      gamefitPermission: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async handleRefreshButton() {
      this.qsReports = [];
      this.loading = true;
      await API.graphql({
        query: refreshDashboards,
      });
      await this.fetchQuickSightDashboardUrlsAppSync();
    },
    viewReport(path) {
      this.$router.push(path);
    },
    async selectedReport(dashboardId, dashboardName) {
      this.$overlay.show("Opening " + dashboardName);
      const userData = await Auth.currentSession();

      if (dashboardName === "GameFit Email Download") {
        if (this.gamefitPermission) {
          const gamefitUrl = await API.graphql({
            query: getUploadToBbUrlAndAuthKey,
            variables: {
              application: "ASSORTMENT_PLANNING_TOOL",
              module: "PLANNING",
              sub_module: "GAME_FIT",
            },
          });
          let url = gamefitUrl.data.getUploadToBbUrlAndAuthKey[0].value_;
          url = `${url}${userData.idToken.jwtToken}`;
          window.open(url, "_blank");
        }
      } else {
        try {
          const { data } = await API.graphql({
            query: fetchDashboardEmbedUrl,
            variables: {
              dashboardId: dashboardId,
              username: userData.idToken.payload["cognito:username"],
              email: userData.idToken.payload.email,
            },
          });
          const url = data.fetchDashboardEmbedUrl;
          window.open(url, "_blank");
        } catch (e) {
          this.$overlay.show("Failed to load dashboard");
        }
      }
      this.$overlay.hide();
    },
    async checkgamefitPermission() {
      const { data } = await API.graphql({
        query: fetchGameFitPermission,
        variables: { email: this.user.email },
      });
      return data.fetchGameFitPermission;
    },
    async fetchQuickSightDashboardUrlsAppSync() {
      const data = await Auth.currentSession();
      const qsDashboardTmp = await API.graphql({
        query: getQuickSightDashboards,
        variables: {
          jwtToken: data.idToken.jwtToken,
          payloadSub: data.idToken.payload.sub,
          email: data.idToken.payload.email,
          username: data.idToken.payload["cognito:username"],
        },
      });
      this.gamefitPermission = await this.checkgamefitPermission();

      const dashboardData = qsDashboardTmp.data.getQuickSightDashboards;

      for (let i = 0; i < dashboardData.length; i++) {
        let repObj = { name: "", dashboardId: "" };
        repObj["name"] = dashboardData[i].name;
        repObj["dashboardId"] = dashboardData[i].dashboardId;
        this.qsReports.push(repObj);
      }

      if (this.gamefitPermission) {
        const repObj = {
          name: "GameFit Email Download",
          dashboardId: null,
        };
        this.qsReports.push(repObj);
      }
      this.loading = false;
    },
  },

  mounted() {
    this.fetchQuickSightDashboardUrlsAppSync();
  },
};
</script>
<style scoped lang="scss">
.category__tile {
  cursor: pointer;
  height: 125px;
  a {
    color: #fff;
    text-decoration: none;
    text-align: right;
    width: 100%;
    text-transform: capitalize;
  }
}
</style>
